import { useLocation } from "@reach/router"
import dayjs from "dayjs"
import { navigate } from "gatsby"
import jwtDecode from "jwt-decode"
import React, { createContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import BrinkApi from "./utils/BrinkApi"
import * as events from "./utils/events"
import { useBrinkStoreConfig } from "./utils/useBrinkStoreConfig"

export const BrinkContext = createContext()

const BRINK_CART_LOCAL_STORAGE_KEY = "BrinkCart"
const BRINK_LIST_NAME_LOCAL_STORAGE_KEY = "BrinkListName"
const BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY = "BrinkShippingAddress"

const BRINK_COMMERCE_SESSION_COOKIE = "brinkcommerceSessionId"
const SHOPPER_REFERENCE_COOKIE = "shopperReference"
const SHOPPER_STORE_COOKIE = "shopperStore"
const DISCOUNT_CODE_COOKIE = "discountCode"
const DISCOUNT_CODE_POPUP_COOKIE = "discountCodePopup"

const CART_CLOSED = "CLOSED"

export const BrinkContextProvider = ({ children }) => {
  const BRINKCOMMERCE_API_URL = process.env.GATSBY_BRINKCOMMERCE_API_URL
  const ADYEN_ENVIRONMENT = process.env.GATSBY_ADYEN_ENVIRONMENT
  const ADYEN_CLIENT_KEY = process.env.GATSBY_ADYEN_CLIENT_KEY
  const ENABLED_COUNTRIES = process.env.GATSBY_ENABLED_COUNTRIES
    ? process.env.GATSBY_ENABLED_COUNTRIES.split(",")
    : []
  const DEFAULT_COUNTRY = process.env.GATSBY_DEFAULT_COUNTRY
  const ENABLE_KLARNA_CHECKOUT = /true/i.test(
    process.env.GATSBY_ENABLE_KLARNA_CHECKOUT
  )

  const location = useLocation()

  const [cookies, _setCookie, removeCookie] = useCookies([
    BRINK_COMMERCE_SESSION_COOKIE,
    SHOPPER_REFERENCE_COOKIE,
    SHOPPER_STORE_COOKIE,
    DISCOUNT_CODE_COOKIE
  ])

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  const storeOptions = useBrinkStoreConfig()
  const { i18n } = useTranslation()

  const isBrowser = typeof window !== "undefined"

  const setCookie = (name, value, days) => {
    isBrowser &&
      _setCookie(name, value, {
        path: "/",
        expires: dayjs().add(days, "days").toDate()
      })
  }

  const [stores] = useState(() => {
    const stores = storeOptions
      .filter((c) =>
        ENABLED_COUNTRIES.length
          ? ENABLED_COUNTRIES.includes(c.countryCode)
          : true
      )
      .map((it) => ({
        currencyUnit: it.currencyUnit,
        countryCode: it.countryCode,
        languageCode: it.languageCode,
        taxPercentage: it.tax
      }))
      .sort((a, b) => a.countryCode.localeCompare(b.countryCode))

    return stores
      .filter((store) => store.countryCode !== DEFAULT_COUNTRY)
      .concat(stores.filter((store) => store.countryCode === DEFAULT_COUNTRY))
  })

  const hostCountryCode = (host) => {
    if (!host) {
      return DEFAULT_COUNTRY
    }
    const parts = host.split(".")
    const countryCode = parts[parts.length - 1] || ""
    return countryCode.toUpperCase()
  }

  const getCountryCodeFromPath = (pathname) => {
    const parts = pathname.split("/")
    const countryCode = parts[1] || ""
    return countryCode.toUpperCase()
  }

  const getStoreFromCountryCode = (countryCode) =>
    stores.find((store) => store.countryCode === countryCode) ||
    stores.find(
      (store) => store.countryCode === hostCountryCode(location.host)
    ) ||
    stores.find((store) => store.countryCode === DEFAULT_COUNTRY)

  const [currentStore, _setCurrentStore] = useState(() => {
    const countryCode = getCountryCodeFromPath(location.pathname)
    return getStoreFromCountryCode(countryCode)
  })

  const [headStore, setHeadStore] = useState()

  const setCurrentStore = async (store) => {
    // setCountryWhiteList([store.countryCode])
    setShippingAddress({ country: store.countryCode })
    setCookie(SHOPPER_STORE_COOKIE, store.countryCode, 365)
    _setCurrentStore(store)
    setHeadStore(store)
    events.changeLocation(store)
  }

  const setShippingMethod = (shippingOption) => {
    _setShippingMethod({
      id: shippingOption.id,
      label:
        shippingOption.displayName[languageCode] ||
        shippingOption.displayName.en,
      price: shippingOption.price[currentStore.currencyUnit],
      icon: shippingOption.mainImage?.asset.url
    })
    updateProductVariantInCart({
      productVariantId: shippingOption.id,
      quantity: 1,
      shipping: true
    })
  }

  const [countryWhiteList, setCountryWhiteList] = useState([
    ...storeOptions.map((store) => store.countryCode)
  ])
  const [brinkSessionId, _setBrinkSessionId] = useState(
    () => cookies[BRINK_COMMERCE_SESSION_COOKIE]
  )

  const setBrinkSessionId = (sessionId) => {
    setCookie(BRINK_COMMERCE_SESSION_COOKIE, sessionId, 31)
    _setBrinkSessionId(sessionId)
  }

  const languageCode = storeOptions.find(
    (s) => s.languageCode === i18n.language.slice(0, 2)
  )
    ? i18n.language
    : process.env.GATSBY_BUILD_LANGUAGE || "en"

  const getAdditionalLanguages = () =>
    process.env.GATSBY_ADDITIONAL_LANGUAGES
      ? process.env.GATSBY_ADDITIONAL_LANGUAGES.split(",")
      : ["en", "sv", "de"]

  const [supportedLanguages] = useState(() => [
    ...new Set([
      process.env.GATSBY_BUILD_LANGUAGE || "en",
      ...getAdditionalLanguages()
    ])
  ])

  const [shopperReference] = useState(() => {
    if (!cookies[SHOPPER_REFERENCE_COOKIE]) {
      setCookie(SHOPPER_REFERENCE_COOKIE, uuidv4(), 365)
    }
    return cookies[SHOPPER_REFERENCE_COOKIE]
  })

  const [notification, setNotification] = useState({
    [NotificationTypes.CART]: {
      event: null,
      severity: null,
      message: null,
      processing: null
    },
    [NotificationTypes.CHECKOUT]: {
      event: null,
      severity: null,
      message: null,
      processing: null
    }
  })

  const [shippingAddress, _setShippingAddress] = useState(() => {
    const localstorageShippingAddress = isBrowser
      ? localStorage.getItem(BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY)
      : null
    return localstorageShippingAddress
      ? JSON.parse(decodeUnicode(localstorageShippingAddress))
      : {}
  })
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState({})
  const [searchQuery, setSearchQuery] = useState(null)
  const [searchId, setSearchId] = useState(null)
  const [discountCode, _setDiscountCode] = useState(
    () => cookies[DISCOUNT_CODE_COOKIE]
  )
  const [discountCodePopup, _setDiscountCodePopup] = useState(
    () => cookies[DISCOUNT_CODE_POPUP_COOKIE]
  )
  const [listName, _setListName] = useState(() => {
    const localstorageListName = isBrowser
      ? localStorage.getItem(BRINK_LIST_NAME_LOCAL_STORAGE_KEY)
      : null
    return localstorageListName
      ? JSON.parse(decodeUnicode(localstorageListName))
      : null
  })

  const setDiscountCode = (discountCode) => {
    setCookie(DISCOUNT_CODE_COOKIE, discountCode, 3)
    _setDiscountCode(discountCode)
  }

  const deleteDiscountCode = () => {
    _setDiscountCode()
    removeCookie(DISCOUNT_CODE_COOKIE, { path: "/" })
  }

  const setDiscountCodePopup = (discountCodePopup) => {
    setCookie(DISCOUNT_CODE_POPUP_COOKIE, discountCodePopup, 3)
    _setDiscountCodePopup(discountCodePopup)
  }

  const deleteDiscountCodePopup = () => {
    _setDiscountCodePopup()
    removeCookie(DISCOUNT_CODE_POPUP_COOKIE, { path: "/" })
  }

  const getDiscountCode = (cart) => {
    const rules =
      cart.discounts &&
      cart.discounts.rules &&
      cart.discounts.rules.filter((r) => r.ruleType === "DISCOUNTCODE")
    return rules && rules.length > 0
      ? rules[0].ruleData.discountCode
      : undefined
  }

  const [cart, _setcart] = useState(() => {
    const localstorageCart = isBrowser
      ? localStorage.getItem(BRINK_CART_LOCAL_STORAGE_KEY)
      : null
    const cart = localstorageCart
      ? JSON.parse(decodeUnicode(localstorageCart))
      : {
          cartItems: [],
          totalPrice: 0,
          totalPriceWithDiscount: 0,
          totalDiscountAmount: 0,
          discounts: {}
        }
    const discountCode = getDiscountCode(cart)
    discountCode && setDiscountCode(discountCode)
    return cart
  })

  const [shippingMethod, _setShippingMethod] = useState(() => {
    const shipping =
      cart && cart.cartItems.find((c) => c.type === "shippingOption")
    return shipping
      ? {
          id: shipping.id,
          label:
            shipping.attribute.displayName[languageCode] ||
            shipping.attribute.displayName.en,
          price: shipping.price[currentStore.currencyUnit],
          icon: shipping.imageUrl
        }
      : null
  })

  useMountEffect(() => {
    const fetchAndSetStore = async () => {
      const countryCode = getCountryCodeFromPath(location.pathname)
      const store = getStoreFromCountryCode(countryCode)

      setCurrentStore(store)
      if (brinkSessionId) {
        updateStoreInCart(store)
      }
    }
    fetchAndSetStore()
    isCartExpired() && closeCart()
  })

  const setShippingAddress = (inShippingAddress) => {
    _setShippingAddress({ ...shippingAddress, ...inShippingAddress })
    if (isBrowser) {
      localStorage.setItem(
        BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY,
        encodeUnicode(
          JSON.stringify({ ...shippingAddress, ...inShippingAddress })
        )
      )
    }
  }

  const setListName = (listName) => {
    _setListName(listName)
    if (isBrowser) {
      localStorage.setItem(
        BRINK_LIST_NAME_LOCAL_STORAGE_KEY,
        encodeUnicode(JSON.stringify(listName))
      )
    }
  }

  const setCart = (cart) => {
    _setcart(cart)
    if (isBrowser) {
      localStorage.setItem(
        BRINK_CART_LOCAL_STORAGE_KEY,
        encodeUnicode(JSON.stringify(cart))
      )
    }
    const discountCode = getDiscountCode(cart)
    discountCode && setDiscountCode(discountCode)
  }

  const removeProductFromCart = (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    updateProductVariantInCart({ productVariantId, quantity: 0 })
    events.removeFromCart(
      productVariantId,
      p.name,
      p.quantity,
      p.category,
      listName
    )
  }

  const plusOneProductVariantToCart = async (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    const cartResponse = await updateProductVariantInCart({
      productVariantId: p.id,
      quantity: 1
    })
    events.addToCart(
      cartResponse,
      productVariantId,
      1,
      currentStore,
      p.category,
      listName,
      p.name
    )
  }

  const minusOneProductVariantToCart = (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    updateProductVariantInCart({ productVariantId: p.id, quantity: -1 })
    events.removeFromCart(
      productVariantId,
      p.name,
      1,
      p.category,
      listName,
      p.price,
      p?.discount,
      currentStore
    )
  }

  const addProductVariantsToCart = async (
    productVariantId,
    quantity,
    openCart = false,
    discountCode,
    redirectToCheckout = false
  ) => {
    const cartResponse = await updateProductVariantInCart({
      productVariantId: productVariantId,
      quantity: quantity,
      openCart: openCart,
      inDiscountCode: discountCode
    })
    const p = cartResponse.cartItems.find((p) => p.id === productVariantId)
    events.addToCart(
      cartResponse,
      productVariantId,
      quantity,
      currentStore,
      p.category,
      listName,
      p.name
    )
    if (redirectToCheckout) {
      navigate("/checkout")
    }
  }

  const removeDiscountFromCart = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const responseData = await brinkApi.removeDiscount({
        headers: { Authorization: brinkSessionId }
      })
      setBrinkSessionId(responseData.jwtToken)
      setCart(responseData.cart)
      deleteDiscountCode()
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const updateProductVariantInCart = async ({
    productVariantId,
    quantity,
    openCart = false,
    shipping = false,
    inDiscountCode
  }) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    const products = getProducts({ productVariantId, quantity }, shipping)
    let responseData
    try {
      if (isCartClosed()) {
        localStorage.removeItem(BRINK_CART_LOCAL_STORAGE_KEY)
        responseData = await brinkApi.syncCart({
          action: "post",
          products: products,
          discountCode: inDiscountCode || discountCode || discountCodePopup,
          currencyUnit: currentStore.currencyUnit,
          languageCode: languageCode,
          countryCode: currentStore.countryCode,
          taxPercentage: currentStore.taxPercentage
        })
      } else {
        responseData = await brinkApi.syncCart({
          headers: { Authorization: brinkSessionId },
          action: "put",
          discountCode: inDiscountCode || discountCode || discountCodePopup,
          products: products
        })
      }
      if (responseData.error) {
        setIsLoading(false)
        setIsCartOpen(false)
        if (responseData.error === "Cart closed") {
          closeCart()
        }
        navigate(
          responseData.error === "Out of stock"
            ? "/error-out-of-stock/"
            : "/error/"
        )
        return
      }
      responseData.jwtToken && setBrinkSessionId(responseData.jwtToken)
      responseData.cart && setCart(responseData.cart)
      setIsLoading(false)
      openCart && setIsCartOpen(true)
      return responseData.cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const [showCountrySelector, setShowCountrySelector] = useState(false)

  const updateStoreInCart = async (store) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const responseData = await brinkApi.updateStoreInCart({
        headers: { Authorization: brinkSessionId },
        store
      })
      setBrinkSessionId(responseData.jwtToken)
      setCart(responseData.cart)
      setIsLoading(false)
      return responseData
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return error
    }
  }

  const changeLocation = (countryCode) => {
    const store = getStoreFromCountryCode(countryCode)
    events.updateSetting({ location: store.countryCode })
    const currentPathnameAsArray = location.pathname.match(/\/([a-zA-Z0-9-]*)/g)
    currentPathnameAsArray[0] = `/${store.countryCode.toLowerCase()}`
    window.location.href = currentPathnameAsArray.join("")
  }

  const getProducts = ({ productVariantId, quantity }, shipping) => {
    const products = [{ id: productVariantId, quantity }]
    return !shipping && shippingMethod
      ? [...products, { id: shippingMethod.id, quantity: 1 }]
      : products
  }

  const closeCart = () => {
    const closedCart = {
      ...cart,
      state: CART_CLOSED,
      cartItems: []
    }
    setCart(closedCart)
  }

  const isCartClosed = () =>
    (cart.state || "").toUpperCase() === CART_CLOSED || !hasSessionId()

  const isCartExpired = () => isCartClosed() && !hasSessionId()

  const hasSessionId = () => (brinkSessionId || "").split(".").length === 3

  const addDiscount = async ({ code, checkout = true }) => {
    if (!isCartClosed() && cart.cartItems.length) {
      const brinkApi = new BrinkApi({
        url: BRINKCOMMERCE_API_URL,
        setNotification: setNotification
      })

      const products = shippingMethod
        ? [{ id: shippingMethod.id, quantity: 1 }]
        : []
      try {
        const responseData = await brinkApi.addDiscount({
          headers: { Authorization: `Bearer ${brinkSessionId}` },
          code: code,
          products: products
        })
        setIsLoading(false)
        if (responseData) {
          setCart(responseData.cart)
          if (checkout) {
            typeof window.gtag !== "undefined" &&
              window.gtag("event", "checkout_progress", {
                items: cart.cartItems.map((i) => ({
                  id: i.id,
                  name: i.name,
                  brand: "COMIS",
                  category: i.category,
                  quantity: i.quantity,
                  listName: listName
                })),
                coupon: code
              })
            events.addDiscount(code, responseData.cart)
          }
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
        navigate("/error/")
      }
    }
  }

  const cartToAdyenOrder = async (blockedPaymentMethods = []) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.cartToAdyenOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        email: shippingAddress.email,
        shippingAddress: toOrderShippingAddress(),
        billingAddress: toOrderBillingAddress(),
        blockedPaymentMethods,
        storePaymentMethod: true,
        shopperReference: shopperReference,
        languageCode,
        returnUrl: getReturnUrl()
      })
      if (responseData.error) {
        if (
          responseData.error === "Cart closed" ||
          responseData.error === "Order is already successful"
        ) {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/",
          "Order is already successful": "/"
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getReturnUrl = () =>
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/${currentStore.countryCode.toLowerCase()}/router/`

  const searchProducts = async (searchQuery) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const products = (
        await brinkApi.searchProducts({ query: searchQuery })
      ).products.filter(
        (product) =>
          product.type === "product" || product.type === "productVariant"
      )
      setIsLoading(false)
      return products
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const cartToOrder = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.cartToOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        email: shippingAddress.email,
        shippingAddress: toOrderShippingAddress(),
        billingAddress: toOrderBillingAddress()
      })
      if (responseData.error && responseData.error === "Cart closed") {
        closeCart()
        navigate("/error/")
        return null
      }
      if (responseData.error && responseData.error === "Out of stock") {
        navigate("/error-out-of-stock/")
        return
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const cartToKlarnaOrder = async (options = {}) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    const merchantUrls = {
      terms: `${window.location.origin}/terms-and-conditions`,
      checkout: `${window.location.origin}/checkout/?klarnaOrderId={checkout.order.id}`,
      confirmation: `${window.location.origin}/success-klarna/?klarnaOrderId={checkout.order.id}`
    }

    const merchantData = {
      errorPage: `${window.location.origin}/error/`,
      errorOutOfStockPage: `${window.location.origin}/error-out-of-stock/`
    }

    try {
      const responseData = await brinkApi.cartToKlarnaOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        merchantUrls,
        merchantData: JSON.stringify(merchantData),
        options
      })
      if (responseData.error && responseData.error === "Cart closed") {
        closeCart()
        navigate("/error/")
        return null
      }
      if (responseData.error && responseData.error === "Out of stock") {
        navigate("/error-out-of-stock/")
        return
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getKlarnaOrder = async (orderId) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getKlarnaOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getShippingOptions = async (countryCode) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getShippingOptions({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        countryCode: countryCode
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const updateIngridShippingOption = async (countryCode, postalCode) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const responseData = await brinkApi.initializeIngridDeliveryCheckout({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        countryCode: countryCode,
        postalCode: postalCode
      })
      responseData.jwtToken && setBrinkSessionId(responseData.jwtToken)
      setCart(responseData.cart)
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getSetCart = async (sessionId, token) => {
    setBrinkSessionId(token)
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const cart = await brinkApi.getCart(sessionId)
      if (cart.state !== "ACTIVE") {
        console.error("Cart closed")
        setIsLoading(false)
        closeCart()
        navigate("/error/")
        return
      }
      setCart(cart)
      return cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const getCart = async () => {
    const sessionId = jwtDecode(brinkSessionId).sessionId
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const cart = await brinkApi.getCart(sessionId)
      setIsLoading(false)
      setCart(cart)
      return cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const getOrderConfirmation = async (orderId, signature) => {
    setIsLoading(true)
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    return brinkApi
      .getOrderConfirmation(orderId, signature)
      .finally(() => setIsLoading(false))
  }

  const getInstagramLatestImages = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    return await brinkApi.getInstagramLatestImages()
  }

  const getStocks = async (productIds) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getStocks(productIds)
    } catch (error) {
      setIsLoading(false)
      return []
    }
  }

  const toOrderShippingAddress = () => ({
    givenName: shippingAddress.firstName,
    familyName: shippingAddress.lastName,
    phone: shippingAddress.phone,
    streetAddress: shippingAddress.address,
    houseNumberOrName: shippingAddress.houseNumberOrName,
    postalCode: shippingAddress.postalCode,
    city: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country
  })

  const toOrderBillingAddress = () => ({
    givenName: shippingAddress.firstName,
    familyName: shippingAddress.lastName,
    phone: shippingAddress.phone,
    streetAddress: shippingAddress.address,
    houseNumberOrName: shippingAddress.houseNumberOrName,
    postalCode: shippingAddress.postalCode,
    city: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country
  })

  const contextObjects = {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    ENABLE_KLARNA_CHECKOUT,
    cartToAdyenOrder,
    cartToOrder,
    cartToKlarnaOrder,
    getKlarnaOrder,
    addDiscount,
    removeProductFromCart,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    addProductVariantsToCart,
    removeDiscountFromCart,
    discountCode,
    deleteDiscountCode,
    setDiscountCode,
    discountCodePopup,
    deleteDiscountCodePopup,
    setDiscountCodePopup,
    cart,
    changeLocation,
    updateStoreInCart,
    setNotification,
    notification,
    languageCode,
    isLoading,
    setIsLoading,
    isCartOpen,
    setIsCartOpen,
    shippingAddress,
    setShippingAddress,
    shippingMethod,
    order,
    setOrder,
    closeCart,
    isCartClosed,
    isCartExpired,
    stores,
    supportedLanguages,
    setSearchQuery,
    searchQuery,
    searchProducts,
    setCurrentStore,
    currentStore,
    searchId,
    setSearchId,
    countryWhiteList,
    setShippingMethod,
    getSetCart,
    getCart,
    brinkSessionId,
    getShippingOptions,
    updateIngridShippingOption,
    setCookie,
    getInstagramLatestImages,
    setListName,
    listName,
    getStocks,
    getOrderConfirmation,
    headStore,
    getStoreFromCountryCode,
    showCountrySelector,
    setShowCountrySelector
  }

  return (
    <BrinkContext.Provider value={contextObjects}>
      {children}
    </BrinkContext.Provider>
  )
}

export const NotificationTypes = {
  CART: "CART",
  CHECKOUT: "CHECKOUT"
}

export const NotificationSeverityLevel = {
  ERROR: "ERROR",
  INFO: "INFO"
}

export const NotificationEvent = {
  UPDATE_CART: "UPDATE_CART",
  CREATE_PAYMENT_CHECKOUT: "CREATE_PAYMENT_CHECKOUT",
  PROCESSING_PAYMENT: "PROCESSING_PAYMENT",
  APPLYING_DISCOUNT_CODE: "APPLYING_DISCOUNT_CODE",
  REMOVE_DISCOUNT_CODE: "REMOVE_DISCOUNT_CODE",
  PROCESSING_CONFIRMATION: "PROCESSING_CONFIRMATION"
}

const encodeUnicode = (str) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode("0x" + p1)
    )
  )

const decodeUnicode = (str) =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )
